import React, { lazy, Suspense, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import DocumentTitle from 'react-document-title';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import config from 'config';
import { Box } from 'material-latest';

import Layout from '../../../../components/LayoutNew';
import CustomModal from 'modules/common/CustomModal';
import TextInput from 'modules/common/TextInput';
import Button from 'modules/common/PrimaryButton';
import CustomLink from 'modules/common/CustomLink';
import Picker from 'modules/common/Picker';
import styles from './styles';
import { email, required, checkNonLatin } from 'utils/validation/fieldValidationRules';
import {
  WRONG_GP_MODAL,
  RESET_PASSWORD_MODAL,
  REDIRECT_TO_AFTERPRIME_MODAL,
  FORGOT_PASSWORD_MODAL,
  REQUIRED_VERIFICATION_MODAL,
  EMAIL_VERIFICATION_MODAL,
} from '../../constants';
import {
  GLOBAL_PRIME_LABEL,
  GLOBAL_PRIME_X_LABEL,
  GLOBAL_PRIME_PREFIX,
  GLOBAL_PRIME_X_PREFIX,
} from '../../../../../../constants';
import { getTranslation } from 'utils/compositeTranslationHandler';
import { getStaticSiteUrl } from 'utils/getStaticSiteUrl';
import SelectHub from '../SelectHub';
import Spinner from 'modules/common/Spinner';
import { setMetaDataDescription } from 'utils/setMetaDataDescription';
import ReCaptcha from 'modules/common/ReCaptcha';

const WrongGPModal = lazy(() => import('../WrongGPModal'));
const ResetPasswordModal = lazy(() => import('../ResetPasswordModal'));
const EmailVerificationModal = lazy(() => import('../EmailVerificationModal'));
const RedirectToAfterprimeModal = lazy(() => import('../RedirectToAfterprimeModal'));
const RequiredVerificationModal = lazy(() => import('../RequiredVerificationModal'));

const HUBS = [
  { value: GLOBAL_PRIME_PREFIX, label: GLOBAL_PRIME_LABEL },
  { value: GLOBAL_PRIME_X_PREFIX, label: GLOBAL_PRIME_X_LABEL },
];

const SYMBOLS_IN_ROW = 62;

const SignInForm = ({
  classes,
  handleSubmit,
  error = null,
  isPending,
  redirectHub,
  closeGPModal,
  closeRedirectToAfterprimeModal,
  closeResetPasswordModal,
  redirectToSignIn,
  forgotPasswordRequest,
  forgotPasswordRequestPending,
  closeEmailVerificationModal,
  prefix = '',
  internal,
  selectHub,
  // onHubSelect,
  blockedHubs,
  form,
  showCaptcha,
}) => {
  const { t } = useTranslation('auth');
  const { CURRENT_HUB_LABEL, LOGO, IS_CLIM, SIGNIN_DISCLAIMER } = config;
  const errorRows = useMemo(() => {
    if (!error) return 1;
    return Math.ceil(getTranslation(t, error).length / SYMBOLS_IN_ROW);
  }, [error, t]);

  React.useEffect(() => {
    setMetaDataDescription(t('signIn.documentDescription', { company: CURRENT_HUB_LABEL }));
  }, [CURRENT_HUB_LABEL, t]);

  return (
    <Layout className={classes.spaceForFooter}>
      <div className={classNames({ [classes.mainLayout]: SIGNIN_DISCLAIMER })}>
        <DocumentTitle title={t('signIn.documentTitle', { company: CURRENT_HUB_LABEL })} />

        <div className={classes.formWrapper}>
          <CustomLink className={classes.logoWrapper} to={getStaticSiteUrl()}>
            <img src={LOGO.blue} className={classes.logo} alt="black-logo" />
          </CustomLink>
          <form className={classes.form} onSubmit={handleSubmit}>
            <div className={classes.welcomeBack}>{t('logInWelcome')}</div>
            <div className={classes.welcomeBackDescription}>{t('logInWelcomeDescription')}</div>
            <section className={classes.wrapper}>
              {error && (
                <span className={classes.formError}>
                  <ErrorOutline className={classes.errorIcon} />
                  {getTranslation(t, error)}
                </span>
              )}
              {internal ? (
                <Field
                  name="prefix"
                  component={Picker}
                  label={'Hub'}
                  values={HUBS}
                  validate={[required]}
                />
              ) : null}
              <Field
                name="email"
                label={t('common:labels.email')}
                placeholder={t('common:labels.email')}
                type="text"
                component={TextInput}
                focused
                className={classNames({ [classes.climCustomInputs]: IS_CLIM })}
                validate={[checkNonLatin, required, email]}
                style={{ marginTop: 16 * errorRows }}
              />
              <Field
                name="password"
                label={t('common:labels.password')}
                placeholder={t('common:labels.password')}
                isConfidential
                component={TextInput}
                className={classes.textField}
                validate={[required]}
              />
              <section className={classes.forgotPasswordWrapper}>
                <CustomLink
                  className={classNames({ [classes.climCustomLink]: IS_CLIM })}
                  to="/auth/forgot-password">
                  {t('forgotPasswordText')}
                </CustomLink>
              </section>
              {showCaptcha && (
                <Box mt={2}>
                  <ReCaptcha form={form} />
                </Box>
              )}

              {!isPending && (
                <Button
                  fullWidth
                  color="primary"
                  size="large"
                  label={t('buttons.logIn')}
                  type="submit"
                  className={classNames(classes.submitButton, {
                    [classes.climCustomButton]: IS_CLIM,
                  })}
                />
              )}
              {isPending && (
                <div className={classes.loader}>
                  <Spinner />
                </div>
              )}

              <section className={classes.customLinkWrapper}>
                <Typography className={classes.customLinkText} align="center">
                  {t('notAMemberYet')}{' '}
                  <CustomLink
                    className={classNames({ [classes.climCustomLink]: IS_CLIM })}
                    to={`${prefix}/auth/sign-up`}>
                    {t('buttons.signUp')}
                  </CustomLink>
                </Typography>
              </section>
            </section>
            <SelectHub
              isVisible={selectHub && !isPending}
              onSubmit={handleSubmit}
              isLoading={isPending}
              blockedHubs={blockedHubs}
              showCaptcha={showCaptcha}
              form={form}
            />
          </form>
        </div>
        <Suspense fallback="">
          <WrongGPModal
            modalName={WRONG_GP_MODAL}
            redirectHub={redirectHub}
            closeModal={closeGPModal}
          />
          <RedirectToAfterprimeModal
            modalName={REDIRECT_TO_AFTERPRIME_MODAL}
            redirectHub={redirectHub}
            closeModal={closeRedirectToAfterprimeModal}
          />
          <ResetPasswordModal
            modalName={RESET_PASSWORD_MODAL}
            redirectHub={redirectHub}
            closeModal={closeResetPasswordModal}
            forgotPasswordRequest={forgotPasswordRequest}
            forgotPasswordRequestPending={forgotPasswordRequestPending}
          />
          <EmailVerificationModal
            modalName={EMAIL_VERIFICATION_MODAL}
            redirectHub={redirectHub}
            closeModal={closeEmailVerificationModal}
          />
          <CustomModal
            className={classes.modal}
            text={t('forgotPassword.modalText')}
            redirect={redirectToSignIn}
            modalName={FORGOT_PASSWORD_MODAL}
          />
          <Suspense fallback="">
            <RequiredVerificationModal modalName={REQUIRED_VERIFICATION_MODAL} />
          </Suspense>
          {/* {!IS_CLIM && <ChooseHubModal modalName={CHOOSE_HUB_MODAL} onHubSelect={onHubSelect} />} */}
        </Suspense>
      </div>
    </Layout>
  );
};

SignInForm.propTypes = {
  isPending: PropTypes.bool.isRequired,
  redirectHub: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  classes: PropTypes.shape({
    form: PropTypes.string.isRequired,
    wrapper: PropTypes.string.isRequired,
    formError: PropTypes.string.isRequired,
    errorIcon: PropTypes.string.isRequired,
    forgotPasswordWrapper: PropTypes.string.isRequired,
    customLinkWrapper: PropTypes.string.isRequired,
    customLinkText: PropTypes.string.isRequired,
    textField: PropTypes.string.isRequired,
    submitButton: PropTypes.string.isRequired,
  }).isRequired,
  closeGPModal: PropTypes.func.isRequired,
  // onHubSelect: PropTypes.func.isRequired,
  blockedHubs: PropTypes.array,
  form: PropTypes.string.isRequired,
  showCaptcha: PropTypes.bool,
};

export default compose(reduxForm(), withStyles(styles))(SignInForm);
