import { useEffect, useState } from 'react';

export const useIframe = ({
  iframeClass = 'iframeClass',
  wrapperId = 'iframe',
  url,
  styles = { width: '100%', height: 'calc(100vh - 250px)', border: 'none' },
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const isIframeExists = document.getElementsByClassName(iframeClass)[0];

    if (isIframeExists) {
      return;
    }

    const iframe = document.createElement('iframe');
    const iframeWrapper = document.getElementById(wrapperId);
    iframe.onload = function() {
      iframe.style.display = 'initial';
      setIsLoading(false);
    };

    iframe.src = url;
    iframe.style.display = 'none';
    iframe.style.width = styles.width;
    iframe.style.height = styles.height;
    iframe.style.border = styles.border;
    iframe.className = iframeClass;
    iframeWrapper.appendChild(iframe);
  }, []);

  return [isLoading, setIsLoading];
};
