import { createSelector } from 'reselect';

import {
  allowCopyAccount,
  getIsAllowedUpgradeToPro,
  getIsCountryManager,
  getIsFusionPlus,
} from '../../auth/selectors';
import { MENU_ITEM_TYPE } from '../constants';

import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import ListAltIcon from '@mui/icons-material/ListAlt';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import CandlestickChartOutlinedIcon from '@mui/icons-material/CandlestickChartOutlined';
import PieChartOutlinedIcon from '@mui/icons-material/PieChartOutlined';
import ExtensionOutlinedIcon from '@mui/icons-material/ExtensionOutlined';
import InstallMobileOutlinedIcon from '@mui/icons-material/InstallMobileOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import TimelineIcon from '@mui/icons-material/Timeline';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';

import toolsIcon from 'static/ico_tools.svg';
import { Routes } from '../../../constants/routeConstants';
import { Roles } from '../../../constants/userRoles';
import verificationIcon from 'static/ico_id_verification.svg';
import mailManageIcon from 'static/mail_manage.svg';
import userManagementIcon from 'static/ico_user_management.svg';
import { default as migrateMyAccountIcon, default as accountsIcon } from 'static/ico_accounts.svg';
import iconAccounts from 'static/menu/accounts.svg';
import iconDownloads from 'static/menu/downloads.svg';
import iconPayments from 'static/menu/payments.svg';
import iconProfile from 'static/menu/profile.svg';
import iconReduceFees from 'static/menu/reduceFees.svg';
import iconSponsoredVps from 'static/menu/sponsoredVps.svg';
import iconTradingCalculators from 'static/menu/tradingCalculators.svg';
import iconPrimePlus from 'static/menu/primePlus.svg';

import swapCalls from 'static/menu/swap_calls.svg';
import verifiedUser from 'static/menu/verified_user.svg';
import assignmentLate from 'static/menu/assignment_late.svg';
import notificationsActive from 'static/menu/notifications_active.svg';
import gpDbad from 'static/menu/fmd_bad.svg';
import emojiHourglass from 'static/menu/hourglass_empty.svg';
import notListedLocation from 'static/menu/not_listed_location.svg';
import tune from 'static/menu/tune.svg';
import newsStand from 'static/menu/news_stand.svg';
import isMobile from 'utils/isMobile';

export const getMenuItemsList = createSelector(
  getIsAllowedUpgradeToPro,
  allowCopyAccount,
  getIsCountryManager,
  getIsFusionPlus,
  (isAllowedUpgradeToPro, isAllowCopyAccount, isCountryManager, isFusionPlusUser) => [
    {
      id: 'dashboard',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: 'Dashboard',
      icon: toolsIcon,
      url: Routes.DASHBOARD,
      access: [Roles.ADMIN],
      mainMenuItem: true,
      visible: true,
      chinaManagerAllow: isCountryManager,
    },
    {
      id: 'id_verification',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: 'ID Verification',
      icon: verificationIcon,
      url: Routes.VERIFICATION_MANAGEMENT,
      access: [Roles.ADMIN, Roles.MANAGER],
      mainMenuItem: true,
      visible: true,
    },
    {
      id: 'payment_management',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: 'Payment Management',
      icon: iconPayments,
      url: Routes.PAYMENT_MANAGEMENT,
      access: [Roles.ADMIN, Roles.MANAGER],
      mainMenuItem: true,
      visible: true,
    },
    {
      id: 'email_management',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: 'Email Management',
      icon: mailManageIcon,
      url: Routes.EMAIL_MANAGEMENT,
      access: [Roles.ADMIN, Roles.MANAGER],
      mainMenuItem: false,
      visible: true,
    },
    {
      id: 'user_management',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: 'User Management',
      icon: userManagementIcon,
      url: Routes.USER_MANAGEMENT,
      access: [Roles.ADMIN, Roles.MANAGER],
      mainMenuItem: false,
      visible: true,
    },
    {
      id: 'operations',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: 'Operations',
      icon: accountsIcon,
      url: Routes.OPERATIONS,
      access: [Roles.ADMIN],
      mainMenuItem: false,
      visible: true,
    },
    {
      id: 'settingsMenu',
      type: MENU_ITEM_TYPE.NESTED,
      label: 'Settings',
      icon: iconProfile,
      access: [Roles.MANAGER, Roles.ADMIN],
      mainMenuItem: false,
      visible: true,
      items: [
        {
          id: 'migration_requests',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Migration requests',
          icon: swapCalls,
          url: Routes.MIGRATION_REQUESTS,
          access: [Roles.ADMIN, Roles.MANAGER],
          visible: true,
        },
        {
          id: 'security',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Security',
          icon: verifiedUser,
          url: Routes.SECURITY,
          access: [Roles.ADMIN],
          visible: true,
        },
        {
          id: 'pageWarning',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Page Warning',
          icon: assignmentLate,
          url: Routes.PAGE_WARNING,
          access: [Roles.ADMIN],
          visible: true,
        },
        {
          id: 'notifications',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Notifications',
          icon: notificationsActive,
          url: Routes.NOTIFICATIONS,
          access: [Roles.ADMIN],
          visible: true,
        },
        {
          id: 'riskGroup',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Risk Group',
          icon: gpDbad,
          url: Routes.RISK_GROUP,
          access: [Roles.ADMIN],
          visible: true,
        },
        {
          id: 'paymentQueue',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Queue',
          icon: emojiHourglass,
          url: Routes.PAYMENT_QUEUE,
          access: [Roles.ADMIN],
          visible: true,
        },
        {
          id: 'questions',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Custom Fields',
          icon: notListedLocation,
          url: Routes.CUSTOM_FIELDS,
          access: [Roles.ADMIN],
          visible: true,
        },
        {
          id: 'settings',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Settings',
          icon: tune,
          url: Routes.SETTINGS,
          access: [Roles.MANAGER, Roles.ADMIN],
          visible: true,
        },
      ],
    },
    {
      id: 'accounts',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: { key: 'menu:category.accounts' },
      icon: iconAccounts,
      url: Routes.MY_ACCOUNTS,
      access: [Roles.CLIENT],
      mainMenuItem: true,
      visible: true,
    },
    {
      id: 'payments',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: { key: 'menu:category.payments' },
      icon: iconPayments,
      url: Routes.PAYMENTS,
      access: [Roles.CLIENT],
      mainMenuItem: true,
      visible: true,
    },
    {
      id: 'vps',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: { key: 'menu:category.vps' },
      icon: iconSponsoredVps,
      url: Routes.VPS,
      access: [Roles.CLIENT],
      mainMenuItem: false,
      visible: true,
    },
    {
      id: 'tradingCalculators',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: {
        key: isMobile()
          ? 'menu:category.mobileTradingCalculatrs'
          : 'menu:category.tradingCalculators',
      },
      icon: iconTradingCalculators,
      url: Routes.TRADING_CALCULATORS,
      access: [Roles.CLIENT],
      isPublic: true,
      mainMenuItem: true,
      visible: true,
    },
    {
      id: 'downloads',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: { key: 'menu:category.downloads' },
      icon: iconDownloads,
      url: Routes.PLATFORMS,
      access: [Roles.CLIENT],
      mainMenuItem: false,
      visible: true,
    },
    {
      id: 'tradeIdeasMenu',
      type: MENU_ITEM_TYPE.NESTED,
      label: 'Trade Ideas',
      icon: AutoGraphIcon,
      access: [Roles.CLIENT],
      mainMenuItem: false,
      visible: true,
      items: [
        {
          id: 'technical_analysis',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Technical Analysis',
          icon: DashboardOutlinedIcon,
          url: Routes.TRADE_IDEAS_TECHNICAL_ANALYSIS,
          access: [Roles.CLIENT],
          visible: true,
        },
        {
          id: 'performance_statistics',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Performance Statistics',
          icon: PieChartOutlinedIcon,
          url: Routes.TRADE_IDEAS_PERFORMANCE_STATISTICS,
          access: [Roles.CLIENT],
          visible: true,
        },
        {
          id: 'volatility_analysis',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Volatility Analysis',
          icon: BarChartIcon,
          url: Routes.TRADE_IDEAS_VOLATILITY_ANALYSIS,
          access: [Roles.CLIENT],
          visible: true,
        },
        {
          id: 'market_report',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Market Report',
          icon: ListAltIcon,
          url: Routes.TRADE_IDEAS_MARKET_REPORT,
          access: [Roles.CLIENT],
          visible: true,
        },
      ],
    },
    {
      id: 'learningsMenu',
      type: MENU_ITEM_TYPE.NESTED,
      label: 'Learnings',
      icon: RocketLaunchOutlinedIcon,
      access: [Roles.CLIENT],
      mainMenuItem: false,
      visible: true,
      items: [
        {
          id: 'newsSentiment',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'News Sentiment',
          icon: newsStand,
          url: Routes.LEARNINGS_NEWS_SENTIMENT,
          access: [Roles.CLIENT],
          visible: true,
        },
        {
          id: 'education',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Education',
          icon: DashboardOutlinedIcon,
          url: Routes.LEARNINGS_NEWS_EDUCATION,
          access: [Roles.CLIENT],
          visible: true,
        },
      ],
    },
    {
      id: 'messagingAndAllerts',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: 'Messaging & Alerts',
      icon: NotificationsOutlinedIcon,
      url: Routes.MESSAGING_AND_ALERTS,
      access: [Roles.CLIENT],
      mainMenuItem: false,
      visible: true,
    },
    {
      id: 'downloadAutochartist',
      type: MENU_ITEM_TYPE.NESTED,
      label: 'Download Autochartist',
      icon: CandlestickChartOutlinedIcon,
      access: [Roles.CLIENT],
      mainMenuItem: false,
      visible: true,
      items: [
        {
          id: 'tradingTools_downloads',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'MT4/5 Scanner',
          icon: ExtensionOutlinedIcon,
          url: Routes.MT4_DOWNLOADS,
          access: [Roles.CLIENT],
          visible: true,
        },
        {
          id: 'tradingTools_downloads_mobile',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Autochartist Mobile App',
          icon: InstallMobileOutlinedIcon,
          url: Routes.MT4_DOWNLOADS_MOBILE,
          access: [Roles.CLIENT],
          visible: true,
        },
      ],
    },
    {
      id: 'reduceFees',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: { key: 'menu:category.reduceFees' },
      icon: iconReduceFees,
      url: Routes.REDUCE_FEES,
      access: [Roles.CLIENT],
      mainMenuItem: false,
      visible: false,
    },
    {
      id: 'primePlus',
      type: MENU_ITEM_TYPE.NESTED,
      label: 'Global Prime +',
      icon: iconPrimePlus,
      access: [Roles.CLIENT],
      mainMenuItem: false,
      visible: isAllowCopyAccount,
      items: [
        {
          id: 'primePlusAbout',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: `About`,
          url: Routes.PRIME_PLUS_ABOUT,
          access: [Roles.CLIENT],
          visible: isAllowCopyAccount,
          icon: ContactSupportOutlinedIcon,
        },
        {
          id: 'primePlusMyInvitations',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: `My Invitations`,
          url: Routes.PRIME_PLUS_INVITATIONS,
          access: [Roles.CLIENT],
          visible: isAllowCopyAccount,
          icon: ConfirmationNumberOutlinedIcon,
        },
        {
          id: 'primePlusTrades',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Trades',
          url: Routes.PRIME_PLUS_TRADES,
          access: [Roles.CLIENT],
          visible: isAllowCopyAccount && isFusionPlusUser,
          icon: TimelineIcon,
        },
        {
          id: 'primePlusMyFollowers',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'My Followers',
          url: Routes.PRIME_PLUS_MY_FOLLOWERS,
          access: [Roles.CLIENT],
          visible: isAllowCopyAccount,
          icon: GroupAddOutlinedIcon,
        },
        {
          id: 'primePlusMasterAccounts',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'My Active Copiers',
          url: Routes.PRIME_PLUS_MASTER_ACCOUNTS,
          access: [Roles.CLIENT],
          visible: isAllowCopyAccount,
          icon: SupervisorAccountOutlinedIcon,
        },
        {
          id: 'primePlusJoinWaitlist',
          type: MENU_ITEM_TYPE.SIMPLE,
          label: 'Leaderboard',
          url: Routes.PRIME_PLUS_MY_FOLLOWERS_WAITLIST,
          access: [Roles.CLIENT],
          visible: isAllowCopyAccount,
          icon: ListAltOutlinedIcon,
        },
      ],
    },
    {
      id: 'upgradeToPro',
      type: MENU_ITEM_TYPE.SIMPLE,
      label: { key: 'menu:category.upgradeToPro' },
      icon: migrateMyAccountIcon,
      url: Routes.UPGRADE_TO_PRO_V_2,
      access: [Roles.CLIENT],
      mainMenuItem: false,
      visible: isAllowedUpgradeToPro,
    },
  ],
);
