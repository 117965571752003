import React from 'react';
import { matchPath, Redirect } from 'react-router-dom';
import {
  getIsAdminRole,
  getIsManagerRole,
  getIsCountryManager,
  getIsAuthenticated,
} from 'modules/auth/selectors';
import { useSelector } from 'react-redux';

import { Routes } from 'constants/routeConstants';
import { getMenuItemsList } from 'modules/menu/selectors';
import { LOGOUT_REASON } from '../auth/constants';

const validPath = (menuItem, pathname) => {
  const iPath = menuItem.routePath || menuItem.url;
  return matchPath(pathname, iPath);
};

const Home = () => {
  const isManager = useSelector(getIsManagerRole);
  const isAdmin = useSelector(getIsAdminRole);
  const isCountryManager = useSelector(getIsCountryManager);
  const menuItemsList = useSelector(getMenuItemsList);

  const authenticated = useSelector(getIsAuthenticated);

  let path = Routes.MY_ACCOUNTS;

  if (!authenticated) {
    path = Routes.SIGNIN;
  } else if (isAdmin || isCountryManager) {
    path = Routes.DASHBOARD;
  } else if (isManager) {
    path = Routes.VERIFICATION_MANAGEMENT;
  }

  const homePath = menuItemsList.findIndex((item) => {
    if (item.items) {
      return item.items.some((i) => validPath(i, path));
    } else {
      return validPath(item, path);
    }
  });

  if (homePath === -1) {
    path = menuItemsList[0]?.url || {
      pathname: Routes.SIGNIN,
      state: { reason: LOGOUT_REASON.AUTH_FAIL },
    };
  } else if (path === Routes.SIGNIN) {
    path = {
      pathname: Routes.SIGNIN,
      state: { reason: LOGOUT_REASON.AUTH_FAIL },
    };
  }

  return <Redirect to={path} />;
};

export default Home;
