import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { change, getFormValues, initialize, stopSubmit } from 'redux-form';
import { push, replace } from 'connected-react-router';
import get from 'lodash/get';
import Cookies from 'js-cookie';

import * as actionCreators from '../actions';
import {
  ERROR_CAPTCHA,
  ERROR_CHOOSING_HUB,
  ERROR_CHOOSING_HUB_AU,
  ERROR_CHOOSING_HUB_EN,
  ERROR_REDIRECT_AFTERPRIME,
  ERROR_RESET_PASSWORD,
  MIGRATION_ERRORS,
  EMAIL_VERIFICATION_ERROR,
  REDIRECT_TO_AFTERPRIME_MODAL,
  REQUIRED_VERIFICATION_MODAL,
  RESET_PASSWORD_MODAL,
  SIGN_IN_STATUS,
  SIGNIN_FORM,
  WITHDRAWAL_TOKEN,
  WRONG_GP_MODAL,
  EMAIL_VERIFICATION_MODAL,
} from '../constants';
import {
  FOLLOWER_HUB_COOKIE_NAME,
  FOLLOWER_ID_COOKIE_NAME,
  GLOBAL_PRIME_PREFIX,
  GLOBAL_PRIME_X_PREFIX,
} from '../../../../../constants';
import { Routes } from 'constants/routeConstants';
import { closeModal, openModal } from 'modules/common/CustomModal/actions';
import { checkIfNewUserNotificationsRequest } from '../../../../userNotifications/actions';
import { check } from '../../../../permissions';
import * as notificationsActions from 'modules/notifications/actions';
import { LPOA_DOCUMENT_MODAL } from 'modules/lpoa/constants';
import { getUser, getIsClientRole } from '../../../selectors';

function* redirectUser() {
  const user = yield select(getUser);
  const isClient = yield select(getIsClientRole);
  if (!isClient || user.mfa?.enabled || user.mfa?.postponed) {
    yield call(redirectToDashboard);
  } else {
    yield put(replace(Routes.AUTH_MFA_SETUP));
  }
}

export function* redirectToDashboard(action) {
  const withdrawalToken = Cookies.get(WITHDRAWAL_TOKEN);
  const joinPlusToken = Cookies.get(FOLLOWER_ID_COOKIE_NAME);
  if (withdrawalToken) {
    Cookies.remove(WITHDRAWAL_TOKEN);
    yield put(push(`${Routes.PAYMENTS}/${withdrawalToken}`));
  } else if (joinPlusToken) {
    Cookies.remove(FOLLOWER_ID_COOKIE_NAME);
    Cookies.remove(FOLLOWER_HUB_COOKIE_NAME);
    yield put(push(`${Routes.PRIME_PLUS_FOLLOWER_JOIN}/${joinPlusToken}`));
  } else {
    yield put(push(Routes.HOME));
  }

  // check if there are new notifications
  const storedUser = yield select(getUser);

  const user = get(action, 'response.data.user') || storedUser;
  const userId = user._id;
  const userRole = user.role;
  const isPermissible = check(userRole, 'userNotifications:check');

  if (userId && isPermissible) {
    yield put(checkIfNewUserNotificationsRequest(userId));
  }

  const showLpoa = user.showLpoa;

  if (showLpoa) {
    yield put(
      notificationsActions.showNotificationDocument({
        message: 'Click to review and sign Limited Power of Attorney',
        modalName: LPOA_DOCUMENT_MODAL,
      }),
    );
  }
}

function* signInSuccess(action) {
  const status = get(action, 'response.data.status');

  if (status === SIGN_IN_STATUS.NEED_MFA) {
    yield put(push(Routes.AUTH_MFA_INPUT));
  } else if (status === SIGN_IN_STATUS.CHANGE_PASSWORD) {
    yield put(push(Routes.AUTH_FORCE_PASSWORD_CHANGE));
  } else if (status === SIGN_IN_STATUS.REQUIRED_MFA) {
    yield put(push(Routes.AUTH_MFA_REQUIRED));
  } else if (status === SIGN_IN_STATUS.REQUIRED_VERIFICATION) {
    yield put(openModal(REQUIRED_VERIFICATION_MODAL));
  } else {
    yield call(redirectUser);
  }
}

function* showError(action) {
  const errorId = get(action, 'error.data.index');
  const proxyError = get(action, 'error.data.proxyInfo.proxySignInError', null);
  yield put(change(SIGNIN_FORM, 'captcha', ''));

  if (proxyError) {
    const { GLOBAL_PRIME, GLOBAL_PRIME_X } = get(action, 'error.data.proxyInfo', {});
    if (GLOBAL_PRIME || GLOBAL_PRIME_X) {
      yield put(
        stopSubmit(SIGNIN_FORM, {
          _error: proxyError.message?.key || proxyError.message,
        }),
      );
      return;
    }
  }

  if ([ERROR_CHOOSING_HUB, ERROR_CHOOSING_HUB_AU, ERROR_CHOOSING_HUB_EN].includes(errorId)) {
    yield put(actionCreators.setBlockedHubs(errorId));
    const prefix = errorId === ERROR_CHOOSING_HUB_EN ? GLOBAL_PRIME_X_PREFIX : GLOBAL_PRIME_PREFIX;
    yield put(change(SIGNIN_FORM, 'prefix', prefix));
    yield put(actionCreators.selectHub());
    return;
  }
  if (errorId === ERROR_CAPTCHA) {
    yield put(actionCreators.showCaptchaToken(true));
    yield put(
      stopSubmit(SIGNIN_FORM, {
        _error: { key: 'auth:signIn.captchaRequired' },
      }),
    );
    return;
  }
  if (errorId === ERROR_RESET_PASSWORD) {
    yield put(openModal(RESET_PASSWORD_MODAL));
    return;
  }
  if (errorId === EMAIL_VERIFICATION_ERROR) {
    yield put(openModal(EMAIL_VERIFICATION_MODAL));
    return;
  }
  if (errorId === ERROR_REDIRECT_AFTERPRIME) {
    yield put(openModal(REDIRECT_TO_AFTERPRIME_MODAL));
    return;
  }
  const errorStatus = get(action, 'error.status', null);
  const { message: errorMessage, index: errorIndex, extraData } = get(action, 'error.data', {});

  if (errorStatus === 400 && MIGRATION_ERRORS.includes(errorIndex)) {
    yield put(actionCreators.setDestinationHub(extraData.migrationDestination));
    yield put(openModal(WRONG_GP_MODAL));
  }

  if (errorStatus === 429) {
    yield put(
      stopSubmit(SIGNIN_FORM, {
        _error: { key: 'auth:signInTooManyRequests' },
      }),
    );
    return;
  }

  yield put(
    stopSubmit(SIGNIN_FORM, {
      _error: errorMessage,
    }),
  );
}

function* redirectToSignIn() {
  yield put(push('/auth/sign-in'));
}

function* forgotPasswordRequestSend() {
  const payload = yield select(getFormValues(SIGNIN_FORM));
  yield put(actionCreators.forgotPasswordRequest({ email: payload.email }));
}

function* forgotPasswordSuccess() {
  yield put(closeModal(RESET_PASSWORD_MODAL));
}

function* preInsertLogin(action) {
  yield all([put(initialize(SIGNIN_FORM, { email: action.payload })), put(push(Routes.SIGNIN))]);
}

export default function* watchRequest() {
  yield all([
    takeEvery(actionCreators.signinSuccess, signInSuccess),
    takeEvery(actionCreators.signinNonfxSuccess, redirectToDashboard),
    takeEvery(actionCreators.signinFail, showError),
    takeEvery(actionCreators.preInsertLogin, preInsertLogin),
    takeEvery(actionCreators.redirectToSignIn, redirectToSignIn),
    takeEvery(actionCreators.forgotPasswordSuccess, forgotPasswordSuccess),
    takeEvery(actionCreators.forgotPasswordFail, forgotPasswordSuccess),
    takeEvery(actionCreators.forgotPasswordRequestSend, forgotPasswordRequestSend),
  ]);
}
