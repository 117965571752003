import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, useLocation, matchPath } from 'react-router-dom';

import config from 'config';
import { openModal, closeModal } from 'modules/common/CustomModal/actions';
import { createPendingSelector } from 'modules/api/selectors';
import { getParams } from 'selectors';

import SignIn from '../components/SignIn';
import {
  SIGNIN_FORM,
  WRONG_GP_MODAL,
  CHOOSE_HUB_MODAL,
  REDIRECT_TO_AFTERPRIME_MODAL,
  RESET_PASSWORD_MODAL,
  EMAIL_VERIFICATION_MODAL,
} from '../constants';
import * as actions from '../actions';
import {
  getBlockedHubs,
  getDestinationHub,
  getHideMfaToken,
  getSelectHub,
  getShowCaptcha,
} from '../selectors';
import * as notificationsActions from 'modules/notifications/actions';
import { Box } from 'material-latest';
import { Footer } from 'modules/common/Footer';
import { useReCaptchaV3 } from 'hooks/useReCaptchaV3';
import { logout } from '../../../actions';
import { LOGOUT_REASON, RETURN_URL_STORAGE_KEY } from 'modules/auth/constants';
import { Routes } from 'constants/routeConstants';

const SignInContainer = ({ history, prefix, internal }) => {
  const dispatch = useDispatch();
  const { HUBS = [] } = config;
  const { state } = useLocation();

  const { cleanupStore = false } = state ?? {};

  const queryParams = useSelector(getParams);
  const isSigninPending = useSelector(createPendingSelector(actions.signinRequest));
  const forgotPasswordRequestPending = useSelector(
    createPendingSelector(actions.forgotPasswordRequest),
  );
  const destinationHub = useSelector(getDestinationHub);
  const hideMfaToken = useSelector(getHideMfaToken);
  const selectHub = useSelector(getSelectHub);
  const blockedHubs = useSelector(getBlockedHubs);
  const showCaptcha = useSelector(getShowCaptcha);
  const { getCaptchaV3 } = useReCaptchaV3();

  useEffect(() => {
    if (cleanupStore) {
      dispatch(logout({ reason: LOGOUT_REASON.SIGN_IN_CLEANUP }));
    }
  }, [dispatch, cleanupStore]);

  useEffect(() => {
    if (history.location.search === '?migrated') {
      history.replace(history.location.pathname);
      dispatch(
        notificationsActions.showNotificationInfo({
          key: 'migrateMyAccount:congratulationsDescription',
        }),
      );
    }
  }, [dispatch, history]);

  useEffect(() => {
    dispatch(openModal(CHOOSE_HUB_MODAL));
  }, [dispatch]);

  useEffect(() => {
    if (queryParams.email) {
      dispatch(actions.preInsertLogin(queryParams.email));
    }
  }, [dispatch, queryParams.email]);

  const onHubSelect = useCallback(
    (selectedHub) => {
      dispatch(closeModal(CHOOSE_HUB_MODAL));

      if (selectedHub.LABEL !== config.CURRENT_HUB_LABEL) {
        window.location.assign(selectedHub.SIGN_IN_URL);
      }
    },
    [dispatch],
  );

  const closeGPModal = useCallback(() => {
    dispatch(closeModal(WRONG_GP_MODAL));
  }, [dispatch]);

  const closeRedirectToAfterprimeModal = useCallback(() => {
    dispatch(closeModal(REDIRECT_TO_AFTERPRIME_MODAL));
  }, [dispatch]);

  const closeResetPasswordModal = useCallback(() => {
    dispatch(closeModal(RESET_PASSWORD_MODAL));
  }, [dispatch]);

  const redirectToSignIn = useCallback(() => {
    dispatch(actions.redirectToSignIn());
  }, [dispatch]);

  const forgotPasswordRequest = useCallback(() => {
    dispatch(actions.forgotPasswordRequestSend());
  }, [dispatch]);

  const closeEmailVerificationModal = useCallback(() => {
    dispatch(closeModal(EMAIL_VERIFICATION_MODAL));
  }, [dispatch]);

  const handleSubmit = useCallback(
    async (data) => {
      const payload = { ...data, hideMfaToken };

      let returnUrlInfo = sessionStorage.getItem(RETURN_URL_STORAGE_KEY);
      returnUrlInfo = returnUrlInfo ? JSON.parse(returnUrlInfo) : null;

      if (
        returnUrlInfo &&
        matchPath(returnUrlInfo?.pathname, Routes.AUTH_EMAIL_VERIFICATION) &&
        returnUrlInfo?.search
      ) {
        const token = new URLSearchParams(returnUrlInfo.search).get('token');

        if (token) {
          payload.verificationToken = token;
        }
      }

      payload.captchaV3 = await getCaptchaV3();

      const segmentAnonymousId = localStorage.getItem('ajs_anonymous_id');

      if (segmentAnonymousId) {
        const extractedId = segmentAnonymousId.replaceAll('"', '');

        payload.segmentAnonymousId = extractedId;
      }

      dispatch(actions.signinRequest(payload));
    },
    [dispatch, hideMfaToken, getCaptchaV3],
  );

  const redirectHub = useMemo(() => HUBS.find((hub) => hub.KEY === destinationHub), [
    HUBS,
    destinationHub,
  ]);

  return (
    <Box>
      <SignIn
        form={SIGNIN_FORM}
        isPending={isSigninPending}
        redirectHub={redirectHub}
        onSubmit={handleSubmit}
        closeGPModal={closeGPModal}
        closeRedirectToAfterprimeModal={closeRedirectToAfterprimeModal}
        closeResetPasswordModal={closeResetPasswordModal}
        redirectToSignIn={redirectToSignIn}
        forgotPasswordRequest={forgotPasswordRequest}
        forgotPasswordRequestPending={forgotPasswordRequestPending}
        closeEmailVerificationModal={closeEmailVerificationModal}
        onHubSelect={onHubSelect}
        prefix={prefix}
        internal={internal}
        initialValues={{
          prefix: internal && !prefix ? '/au' : prefix,
        }}
        selectHub={selectHub}
        blockedHubs={blockedHubs}
        showCaptcha={showCaptcha}
      />
      <Footer />
    </Box>
  );
};

export default withRouter(SignInContainer);
