import { push } from 'connected-react-router';
import get from 'lodash/get';
import { stopSubmit, setSubmitFailed, updateSyncErrors } from 'redux-form';
import { all, put, takeEvery } from 'redux-saga/effects';
import { forcePasswordChangeFail, forcePasswordChangeSuccess } from '../actions';
import { FORCE_PASSWORD_CHANGE_FORM } from '../constants';
import { Routes } from 'constants/routeConstants';

function* redirectToDashboard() {
  yield put(push(Routes.HOME));
}

function* showError(action) {
  const { message: errorMessage, index } = get(action, 'error.data', {});

  if (index === '13') {
    yield all([
      put(setSubmitFailed(FORCE_PASSWORD_CHANGE_FORM, ['oldPassword'])),
      put(updateSyncErrors(FORCE_PASSWORD_CHANGE_FORM, { oldPassword: errorMessage })),
    ]);
    return;
  }

  yield put(
    stopSubmit(FORCE_PASSWORD_CHANGE_FORM, {
      _error: errorMessage,
    }),
  );
}

export default function* watchRequest() {
  yield all([
    takeEvery(forcePasswordChangeSuccess, redirectToDashboard),
    takeEvery(forcePasswordChangeFail, showError),
  ]);
}
