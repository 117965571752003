import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import { ConnectedRouter } from 'connected-react-router';
import i18n from 'i18n';
import { getUserLanguage } from 'modules/auth/selectors';
import React, { useEffect, Suspense, useRef } from 'react';
import { I18nextProvider } from 'react-i18next';
import { GoogleOAuthProvider } from '@react-oauth/google';
import FreeChat from '../FreeChat';
import { useDispatch, useSelector } from 'react-redux';
import Provider from 'react-redux/es/components/Provider';
import { Route, useHistory, useLocation, matchPath } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/es/integration/react';
import MaintenanceRoutes from 'routesMaintenance';
import NotFoundPage from 'modules/common/NotFoundPage';
import { configureStore, history } from 'store/configureStore';
import ErrorBoundary from '../../modules/errorBoundary';
import CookiesBanner from '../CookiesBanner';
import CustomThemeProvider from '../CustomThemeProvider';
import ScrollToTopWrapper from '../ScrollToTopWrapper';
import styles from './styles';
import { BottomNavigationProvider } from '../../modules/menu/context/bottomNavigationContext';
import FragmentSupportingSwitch from '../FragmentSupportingSwitch';
import useMaintenancePage from '../../hooks/useMaintenancePage';
import CustomThemeProviderMui5 from '../CustomThemeProviderMui5';
import UIComponents from 'modules/UIComponents';

import VerificationModalsManagerContainer from '../../modules/VerificationModalsManager/containers/VerificationModalsManagerContainer';
import config from '../../config';
import { initApplication } from 'modules/rootModule/actions';
import PrivateRoute from 'modules/privateRoute/container/PrivateRouteContainer';
import { ROUTES_CONFIG } from 'components/App/config';
// import LocationHandler from 'components/LocationHandler';
import { Routes } from 'constants/routeConstants';
import Payments from 'modules/payments';
import { getIsImpersonation } from '../../modules/auth/selectors';
import { LOGOUT_REASON, RETURN_URL_STORAGE_KEY } from 'modules/auth/constants';
import { closeModal } from 'modules/common/CustomModal/actions';

export const store = configureStore();
const persistor = persistStore(store);

const RE_LANGUAGE = /\/(en|au)\//;
const RE_PUBLIC_PATHS = /^\/(auth|public)/;

const changePathIfNeeded = (history, pathname, lang) => {
  const path = pathname.replace(RE_LANGUAGE, '/');
  if (RE_PUBLIC_PATHS.test(path)) {
    return;
  }
  const newPath = lang + path;
  if (newPath === pathname) {
    return true;
  }

  const search = document.location.search;

  window.history.replaceState(null, document.title, '/hub' + newPath + `${search ? search : ''}`);
};

const SuspenseLayout = ({ children }) => <Suspense fallback="">{children}</Suspense>;

const RouteHandler = ({ prefix }) => {
  const lang = useSelector(getUserLanguage);
  const isMaintenance = useMaintenancePage();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const prevLocationRef = useRef(location);
  const isImpersonation = useSelector(getIsImpersonation);
  const prevIsImpersonation = useRef(isImpersonation);

  const modals = useSelector((state) => state.modal);

  useEffect(() => {
    dispatch(initApplication());
  }, []);

  // Location handler
  useEffect(() => {
    if (location.pathname !== prevLocationRef.current.pathname) {
      if (
        matchPath(location.pathname, Routes.SIGNIN) &&
        !location.state?.cleanupStore &&
        (!location.state || location.state?.reason !== LOGOUT_REASON.USER_ACTION) &&
        !prevIsImpersonation.current
      ) {
        sessionStorage.setItem(RETURN_URL_STORAGE_KEY, JSON.stringify(prevLocationRef.current));
      }
      prevIsImpersonation.current = isImpersonation;

      Object.entries(modals).forEach(([key, value]) => {
        if (value) {
          dispatch(closeModal(key));
        }
      });

      prevLocationRef.current = location;
    }
  }, [location, dispatch, modals, isImpersonation]);
  // -----------

  useEffect(() => {
    if (!lang || isMaintenance) {
      return;
    }

    changePathIfNeeded(history, history.location.pathname, lang);

    return history.listen((location) => {
      changePathIfNeeded(history, location.pathname, lang);
    });
  }, [history, isMaintenance, lang]);

  if (isMaintenance) {
    return (
      <SuspenseLayout>
        <FragmentSupportingSwitch>{MaintenanceRoutes()}</FragmentSupportingSwitch>
      </SuspenseLayout>
    );
  }

  return (
    <React.Fragment>
      {/* <LocationHandler /> */}
      <PrivateRoute path={prefix + Routes.PAYMENTS}>
        <Payments prefix={prefix} />
      </PrivateRoute>
      {ROUTES_CONFIG.flatMap(({ isPrivate, path, ...props }, index) => {
        if (Array.isArray(path)) {
          return path.map((individualPath, pathIndex) =>
            isPrivate ? (
              <PrivateRoute
                key={`${individualPath}-${pathIndex}-${index}`}
                path={prefix + individualPath}
                {...props}
              />
            ) : (
              <Route
                key={`${individualPath}-${pathIndex}-${index}`}
                path={prefix + individualPath}
                {...props}
              />
            ),
          );
        }

        return isPrivate ? (
          <PrivateRoute key={`${path}-${index}`} path={prefix + path} {...props} />
        ) : (
          <Route key={`${path}-${index}`} path={prefix + path} {...props} />
        );
      })}
      {/* UI Components */}
      {process.env.REACT_APP_ENV === 'development' && (
        <Route path={prefix + '/ui-components'} component={UIComponents} />
      )}
    </React.Fragment>
  );
};

const Router = () => {
  return (
    <SuspenseLayout>
      <FragmentSupportingSwitch>
        {RouteHandler({ prefix: '/en' })}
        {RouteHandler({ prefix: '/au' })}
        {RouteHandler({ prefix: '' })}
        <Route path="*" component={NotFoundPage} />
      </FragmentSupportingSwitch>
    </SuspenseLayout>
  );
};

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <GoogleOAuthProvider clientId={config.GOOGLE_OAUTH.CLIENT_ID}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <ConnectedRouter history={history}>
              <CustomThemeProviderMui5>
                <CustomThemeProvider>
                  <CssBaseline />
                  <ScrollToTopWrapper>
                    <ErrorBoundary>
                      <BottomNavigationProvider>
                        <Router />
                        <FreeChat license="7eb6d36b-9a3a-4468-9400-751f2cc30a3f" />
                      </BottomNavigationProvider>
                      <VerificationModalsManagerContainer />
                    </ErrorBoundary>
                  </ScrollToTopWrapper>
                  <CookiesBanner />
                </CustomThemeProvider>
              </CustomThemeProviderMui5>
            </ConnectedRouter>
          </PersistGate>
        </Provider>
      </GoogleOAuthProvider>
    </I18nextProvider>
  );
};

export default withStyles(styles)(App);
